<template>
  <div class="category">
    <div
      class="item"
      v-for="(item, index) of data"
      :key="index"
      @click="click(item)"
    >
      <div>{{ item.name }}</div>
      <div>
        <img src="@/assets/img/next.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { data: Array },
  data() {
    return {};
  },
  methods: {
    click(item) {
      this.$emit("click", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.category {
  // height: 1000px;
}

.item {
  margin: 10px 10px;
  display: flex;
  padding: 18px 10px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d8d8d8;
  min-height: 40px;
  user-select: none;
  img {
    width: 15px;
  }
}
</style>