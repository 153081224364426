<template>
  <div class="settingMain">
    <NavbarBack :title="'การรับชำระเงิน'"/>
    <!-- <div class="title">การรับชำระเงิน</div> -->
    <div class="listToggle">
      <label>อนุญาตประเภทการชำระ</label>
      <ListToggleSwitch
        :data="listToggle"
        @click="onToggleClick"
        class="listToggleSwitch"
      />
    </div>
    <Category
      class="wrapCategory"
      :data="categoryData"
      v-on:click="categoryClick"
    />
  </div>
</template>

<script>
import Category from "@/views/components/Category";
import NavbarBack from "@/components/NavbarBack";
import { GET, PATCH } from "@/utils/http";
import ListToggleSwitch from "@/components/ListToggleSwitch";

export default {
  components: { Category, NavbarBack, ListToggleSwitch },
  data() {
    return {
      categoryData: [
        {
          name: "ตั้งค่าอนุญาตชำระแบบเครดิต",
          path: "credit",
        },
      ],
      paymentTypes: [],
    };
  },
  computed: {
    listToggle() {
      return this.paymentTypes.map((e) => {
        return { ...e, checked: e.shopPaymentType?.enabled ? true : false };
      });
    },
  },
  methods: {
    categoryClick(item) {
      this.$router.push({
        path: `/settings/receive-payment/${item.path}`,
      });
    },
    async getPaymentTypes() {
      try {
        window.loading(true);
        const { status, body } = await GET(
          `/api/shops/${this.$myShop.id}/paymenttypes`
        );

        if (status !== 200) {
          throw "paymenttypes : not ok is status " + status;
        }

        window.loading(false);
        this.paymentTypes = body.data;
      } catch (err) {
        window.loading(false);
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
      }
    },
    async onToggleClick(result) {
      try {
        window.loading(true);
        const { status } = await PATCH(
          `/api/shops/${this.$myShop.id}/paymenttypes/${result.item.id}/enable/${result.value}`
        );

        if (status !== 200) {
          throw "paymenttypes enable : not ok is status " + status;
        }

        window.loading(false);
      } catch (err) {
        window.loading(false);
        await window.$alert("พบข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
        this.$router.back();
      }
    },
  },
  mounted() {
    this.getPaymentTypes();
  },
};
</script>
<style lang="scss" scoped>
.settingMain {
  padding-top: 60px;
  background: #fff;
  height: 100%;
}

.title {
  text-align: center;
  font-size: 20px;
  padding: 40px 0 10px 0;
}

.wrapCategory {
  padding-top: 10px;
}

.listToggle {
  padding: 10px;
  label {
    padding-left: 8px;
  }
}

.listToggleSwitch {
  height: auto;
}
</style>