<template>
  <div class="listToggleSwitch">
    <div class="item" v-for="(item, index) of data" :key="index">
      <div>{{ item.name }}</div>
      <div>
        <label class="switch">
          <input
            type="checkbox"
            :checked="item.checked"
            @change="clickToggle(item, $event.target.checked)"
          />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListToggleSwitch",
  props: { data: Array },
  data() {
    return {};
  },
  methods: {
    clickToggle(item, check) {
      this.$emit("click", { item: item, value: check });
    },
  },
};
</script>
<style lang="scss" scoped>
.listToggleSwitch {
  height: 100%;
}

.item {
  // margin: 10px 10px;
  display: flex;
  padding: 15px 14px;
  // border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid #d8d8d8;
  min-height: 45px;
  user-select: none;
  box-shadow: 0px 2px 1px -1px rgb(155 155 155 / 21%);
  img {
    width: 15px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

// input:focus + .slider {
//   box-shadow: 0 0 1px var(--primary-color);
// }

input:checked + .slider:before {
  // -webkit-transform: translateX(18px);
  // -ms-transform: translateX(18px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>